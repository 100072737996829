<script setup>
import {useMainStore} from "@/stores/mainStore";
const mainStore = useMainStore();

import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
SwiperCore.use();

</script>
<script>
export default {
  name: "comp-retail",
  components: {  },
  props: [],
  methods: {
    animateNumbers(container) {
      const numElements = container.querySelectorAll('.num')
      const progressBars = container.querySelectorAll('.progressbar > div')

      progressBars.forEach(bar => {
        const targetWidth = parseFloat(bar.innerText)
        const target = parseInt(targetWidth)
        let current = 0
        const steps = 50
        const duration = 1500
        const interval = duration / steps
        const increment = target / steps

        // 시작 시 0으로 설정
        bar.style.width = '0%'

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            bar.style.width = `${target}%`
            clearInterval(counter)
          } else {
            bar.style.width = `${Math.round(current)}%`
          }
        }, interval)
      })

      numElements.forEach(el => {
        // 쉼표를 제거하고 숫자로 변환
        const target = parseFloat(el.innerText.replace(/,/g, ''))
        let current = 0
        const steps = 50
        const increment = target / steps
        const duration = 1500
        const interval = duration / steps

        const decimalPlaces = el.innerText.includes('.')
            ? el.innerText.split('.')[1].length
            : 0

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            // 최종 값에 쉼표 추가
            el.innerText = target.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            clearInterval(counter)
          } else {
            // 중간 값에도 쉼표 추가
            el.innerText = current.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        }, interval)
      })
    },

    initNumberAnimation() {
      const containers = document.querySelectorAll('.numbers-container')
      containers.forEach(container => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.animateNumbers(container) // 해당 컨테이너의 애니메이션 시작
              observer.disconnect() // 애니메이션 후 더 이상 관찰하지 않음
            }
          })
        }, { threshold: 0.1 })

        observer.observe(container) // 각 컨테이너를 관찰
      })
    }
  },
  mounted(){
    new SwiperCore('.slider-container', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.btn-next',
        prevEl: '.btn-prev',
      },
      loop:true,
      autoplay: {
        delay: 3000,
      },
    });
    this.initNumberAnimation()
  },
  unmounted(){
  },
  updated() {
  },
  computed: {
  },
  watch:{
  }
}
</script>
<template>
  <div class="container retail">
    <div class="inner-container">
      <section class="section-1">
        <h1>RETAIL</h1>
        <span class="des">NDIS | RN AUTHENTICATOR</span>
        <img  src="@/assets/images/common/nike_logo_black.svg" alt="">
      </section>
      <section class="section-2">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/title.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/title_m.png" alt="">
      </section>
      <section class="section-3">
        <div class="slider-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_0.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_0_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_1.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_1_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_2.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_2_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_3.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_3_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_4.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_4_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_5.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_5_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_6.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_6_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_7.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_7_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_8.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_8_m.png" alt="">
            </li>
            <li class="swiper-slide">
              <img v-if="!mainStore.isMobile" src="@/assets/images/retail/place_9.png" alt="">
              <img v-if="mainStore.isMobile" src="@/assets/images/retail/place_9_m.png" alt="">
            </li>
          </ul>
          <button class="btn-prev"><img src="@/assets/images/retail/slide_btn_prev.png"></button>
          <button class="btn-next"><img src="@/assets/images/retail/slide_btn_next.png"></button>
        </div>
        <div class="swiper-pagination"></div>
        <div class="retail-support-results">
          <p>RETAIL SUPPORT RESULTS</p>
        </div>
        <div class="numbers-container">
          <div>
            <div>
              <span class="num">113</span>
            </div>
            <span class="sub">STORES</span>
          </div>
          <div>
            <div>
              <span class="num">2</span>
            </div>
            <span class="sub">Pinnacle Stores</span>
          </div>
          <div>
            <div>
              <span class="num">8</span>
            </div>
            <span class="sub">Elevated Stores</span>
          </div>
          <div>
            <div>
              <span class="num">103</span>
            </div>
            <span class="sub">Scaled Stores</span>
          </div>
        </div>
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-4">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/store_run.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/store_run_m.png" alt="">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/STORE.gif" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/Mobile_STORE.gif" alt="">
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-5">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/pegasuspq.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/pegasuspq_m.png" alt="">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/PEGASUS.gif" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/Mobile_PEGASUS.gif" alt="">
      </section>
      <section class="section-6">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/racement.png" alt="">
        <img v-if="!mainStore.isMobile" src="../../assets/images/retail/racement.gif" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/racement_m.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/Mobile_racement.gif" alt="">
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-7">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/what_worked.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/what_worked_m.png" alt="">
      </section>
      <section class="section-8">
        <img v-if="!mainStore.isMobile" src="@/assets/images/retail/soundbites.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/retail/soundbites_m.png" alt="">
        <div class="btn-box">
          <router-link to="/media" class="common-btn">
            LEARN MORE ON<br>
            MEDIA
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/retail';
</style>
