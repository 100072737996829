<script setup>
  import {useMainStore} from "@/stores/mainStore";
  import {onMounted, ref, onUnmounted, watch, nextTick } from "vue";
  const mainStore = useMainStore();

  let sec3Slide1;
  let sec3Slide2;
  let sec3Slide3;

	let sec4Slide1;

  let sec5Slide1;
  let sec5Slide2;
  let sec5Slide3;

  import SwiperCore from 'swiper';
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  SwiperCore.use();

	let animateNumbers = (container) =>{
		const numElements = container.querySelectorAll('.num')
		const progressBars = container.querySelectorAll('.progressbar > div')

		progressBars.forEach(bar => {
			const targetWidth = parseFloat(bar.innerText)
			const target = parseInt(targetWidth)
			let current = 0
			const steps = 50
			const duration = 1500
			const interval = duration / steps
			const increment = target / steps

			// 시작 시 0으로 설정
			bar.style.width = '0%'

			const counter = setInterval(() => {
				current += increment
				if (current >= target) {
					bar.style.width = `${target}%`
					clearInterval(counter)
				} else {
					bar.style.width = `${Math.round(current)}%`
				}
			}, interval)
		})

		numElements.forEach(el => {
			// 쉼표를 제거하고 숫자로 변환
			const target = parseFloat(el.innerText.replace(/,/g, ''))
			let current = 0
			const steps = 50
			const increment = target / steps
			const duration = 1500
			const interval = duration / steps

			const decimalPlaces = el.innerText.includes('.')
				? el.innerText.split('.')[1].length
				: 0

			const counter = setInterval(() => {
				current += increment
				if (current >= target) {
					// 최종 값에 쉼표 추가
					el.innerText = target.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
					clearInterval(counter)
				} else {
					// 중간 값에도 쉼표 추가
					el.innerText = current.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				}
			}, interval)
		})
	};
	let initNumberAnimation = () => {
		const containers = document.querySelectorAll('.numbers-container');
		const animatedContainers = new Set();

		// throttle 함수
		const throttle = (func, limit) => {
			let inThrottle;
			return function() {
				const args = arguments;
				const context = this;
				if (!inThrottle) {
					func.apply(context, args);
					inThrottle = true;
					setTimeout(() => inThrottle = false, limit);
				}
			}
		}

		// 요소가 뷰포트 안에 있는지 확인
		const isElementInViewport = (el) => {
			const rect = el.getBoundingClientRect();
			const windowHeight = window.innerHeight || document.documentElement.clientHeight;

			// 요소가 화면의 아래에서 20% 지점에 도달했을 때 트리거
			return rect.top <= windowHeight * 0.8;
		};

		// 스크롤 핸들러
		const handleScroll = throttle(() => {
			containers.forEach(container => {
				if (animatedContainers.has(container)) return;

				if (isElementInViewport(container)) {
					animateNumbers(container);
					animatedContainers.add(container);
				}
			});

			if (animatedContainers.size === containers.length) {
				window.removeEventListener('scroll', handleScroll);
			}
		}, 100); // 100ms 마다 실행

		// 초기 체크
		handleScroll();

		// 스크롤 이벤트 리스너
		window.addEventListener('scroll', handleScroll);

		// 컴포넌트 언마운트 시 정리 (Vue나 React에서 사용 시)
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	};

  onMounted(() => {
    Promise.all(
        Array.from(document.images)
            .filter(img => !img.complete)
            .map(img => new Promise(resolve => {
              img.onload = img.onerror = resolve;
            }))
    ).then(() => {
      initNumberAnimation();
    });


		sec3Slide1 = new SwiperCore('.sec3-slide-1', {
      pagination: {
        el: '.sec3-slide-1-swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.btn.next.slide-btn-sec3-slide-1',
        prevEl: '.btn.prev.slide-btn-sec3-slide-1',
      },
      loop:true,
      autoplay: {
        delay: 3000,
      },
    });
    sec3Slide2 = new SwiperCore('.sec3-slide-2', {
      pagination: {
        el: '.sec3-slide-2-swiper-pagination',
        clickable: true
      },
      navigation: {
				nextEl: '.btn.next.slide-btn-sec3-slide-2',
				prevEl: '.btn.prev.slide-btn-sec3-slide-2',
      },
      loop:true,
      autoplay: {
        delay: 3000,
      },
    });
    sec3Slide3 = new SwiperCore('.sec3-slide-3', {
      pagination: {
        el: '.sec3-slide-3-swiper-pagination',
        clickable: true
      },
      navigation: {
				nextEl: '.btn.next.slide-btn-sec3-slide-3',
				prevEl: '.btn.prev.slide-btn-sec3-slide-3',
      },
      loop:true,
      autoplay: {
        delay: 3000,
      },
    });

		sec4Slide1 = new SwiperCore('.sec4-slide-1', {
			pagination: {
				el: '.sec4-slide-1-swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.btn.next.slide-btn-sec4-slide-1',
				prevEl: '.btn.prev.slide-btn-sec4-slide-1',
			},
			loop:true,
			autoplay: {
				delay: 3000,
			},
		});

    sec5Slide1 = new SwiperCore('.sec5-slide-1', {
      pagination: {
        el: '.sec5-slide-1-swiper-pagination',
        clickable: true
      },
      navigation: {
				nextEl: '.btn.next.slide-btn-sec5-slide-1',
				prevEl: '.btn.prev.slide-btn-sec5-slide-1',
      },
      loop:true,
      autoplay: {
        delay: 3000,
      },
    });
    sec5Slide2 = new SwiperCore('.sec5-slide-2', {
      pagination: {
        el: '.sec5-slide-2-swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.btn.next.slide-btn-sec5-slide-2',
        prevEl: '.btn.prev.slide-btn-sec5-slide-2',
      },
      loop:true,
      autoplay: {
        delay: 3000,
      },
    });
    sec5Slide3 = new SwiperCore('.sec5-slide-3', {
      pagination: {
        el: '.sec5-slide-3-swiper-pagination',
        clickable: true
      },
      navigation: {
				nextEl: '.btn.next.slide-btn-sec5-slide-3',
				prevEl: '.btn.prev.slide-btn-sec5-slide-3',
      },
      loop:true,
      autoplay: {
        delay: 3000,
      },
    });
  });

  onUnmounted(()=>{
    sec3Slide1.destroy();
    sec3Slide2.destroy();
    sec3Slide3.destroy();
    sec4Slide1.destroy();
    sec5Slide1.destroy();
    sec5Slide2.destroy();
    sec5Slide3.destroy();
  })
</script>
<template>
	<div class="container experience">
		<div class="inner-container">
      <section class="section-1">
        <h1>IRL EXPERIENCE</h1>
        <span class="des">NIKE RUN JEJU</span>
        <img  src="@/assets/images/common/nike_logo_black.svg" alt="">
      </section>
      <section class="section-2">
        <div class="txt-box sec2-1 content-size">
          <img src="../../assets/images/experience/sec2_1.png" v-if="!mainStore.isMobile">
          <img src="../../assets/images/experience/sec2_1_m.png" v-else>
        </div>
        <div class="txt-box sec2-2 numbers-container content-size">
					<div class="num num-0">
						2,800
					</div>
					<div class="num-0 second">
						<span>+</span>
					</div>
					<div class="num num-1">824</div>
					<div class="num num-2">288</div>
					<div class="num num-3">20</div>
					<div class="num num-4">8</div>
					<div class="num num-5">8</div>
          <img src="../../assets/images/experience/sec2_2.png" v-if="!mainStore.isMobile">
          <img src="../../assets/images/experience/sec2_2_m.png" v-else>
        </div>
        <div class="img-box">
          <img src="@/assets/images/experience/sec2_3.png" v-if="!mainStore.isMobile">
          <img src="@/assets/images/experience/sec2_3_m.png" v-else>
        </div>
      </section>
      <section class="section-3">
        <div class="txt-box sec3-1 content-size">
          <img src="@/assets/images/experience/sec3_1.png" v-if="!mainStore.isMobile">
          <img src="@/assets/images/experience/sec3_1_m.png" v-else>
        </div>
        <div class="img-box sec3-2">
<!--          <img src="@/assets/images/experience/sec3_loop.png">-->
        </div>
        <div class="slide-boxes content-size">
          <ul class="slide-list-wrap">
            <li class="slide-lists">
              <div class="txt-box" v-if="!mainStore.isMobile"><img src="@/assets/images/experience/sec3_slide_1_txt.png"></div>
              <div class="txt-box" v-else><img src="@/assets/images/experience/sec3_slide_1_txt_m.png"></div>
              <div class="slide">
                <div class="sec3-slide-1">
                  <ul class="slide-obj swiper-wrapper">
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_0.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_0.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_1.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_1.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_2.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_2.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_3.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_3.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_4.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_4.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_5.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_5.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_6.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_6.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_7.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_7.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_8.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_8.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_9.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_9.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_10.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_10.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-1/community_11.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-1/m/community_11.jpg" v-else>
										</li>
                  </ul>
                </div>
                <div class="btn prev slide-btn-sec3-slide-1"><img src="@/assets/images/experience/slide_btn_prev.png"></div>
                <div class="btn next slide-btn-sec3-slide-1"><img src="@/assets/images/experience/slide_btn_next.png"></div>
                <div class="sec3-slide-1-swiper-pagination swiper-pagination"></div>
              </div>
            </li>
            <li class="slide-lists">
              <div class="txt-box" v-if="!mainStore.isMobile"><img src="@/assets/images/experience/sec3_slide_2_txt.png"></div>
              <div class="txt-box" v-else><img src="@/assets/images/experience/sec3_slide_2_txt_m.png"></div>
              <div class="slide">
                <div class="sec3-slide-2">
                  <ul class="slide-obj swiper-wrapper">
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_0.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_0.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_1.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_1.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_2.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_2.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_3.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_3.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_4.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_4.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_5.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_5.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_6.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_6.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_7.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_7.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_8.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_8.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-2/outside_9.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-2/m/outside_9.jpg" v-else>
										</li>
                  </ul>
                </div>
                <div class="btn prev slide-btn-sec3-slide-2"><img src="@/assets/images/experience/slide_btn_prev.png"></div>
                <div class="btn next slide-btn-sec3-slide-2"><img src="@/assets/images/experience/slide_btn_next.png"></div>
                <div class="sec3-slide-2-swiper-pagination swiper-pagination"></div>
              </div>
            </li>
            <li class="slide-lists">
              <div class="txt-box" v-if="!mainStore.isMobile"><img src="@/assets/images/experience/sec3_slide_3_txt.png"></div>
              <div class="txt-box" v-else><img src="@/assets/images/experience/sec3_slide_3_txt_m.png"></div>
              <div class="slide">
                <div class="sec3-slide-3">
                  <ul class="slide-obj swiper-wrapper">
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_0.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_0.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_1.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_1.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_2.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_2.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_3.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_3.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_4.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_4.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_5.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_5.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_6.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_6.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_7.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_7.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_8.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_8.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_9.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_9.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_10.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_10.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec3-slide-3/peg_11.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec3-slide-3/m/peg_11.jpg" v-else>
										</li>
                  </ul>
                </div>
                <div class="btn prev slide-btn-sec3-slide-3"><img src="@/assets/images/experience/slide_btn_prev.png"></div>
                <div class="btn next slide-btn-sec3-slide-3"><img src="@/assets/images/experience/slide_btn_next.png"></div>
                <div class="sec3-slide-3-swiper-pagination swiper-pagination"></div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="section-4">
        <img src="@/assets/images/experience/sec4.png" v-if="!mainStore.isMobile">
        <img src="@/assets/images/experience/sec4_m.png" v-else>
				<div class="slide-boxes content-size">
					<ul class="slide-list-wrap">
						<li class="slide-lists">
<!--							<div class="txt-box"></div>-->
							<div class="slide">
								<div class="sec4-slide-1">
									<ul class="slide-obj swiper-wrapper">
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec4-slide-1/RACEMENT_0.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec4-slide-1/m/RACEMENT_0.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec4-slide-1/RACEMENT_1.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec4-slide-1/m/RACEMENT_1.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec4-slide-1/RACEMENT_2.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec4-slide-1/m/RACEMENT_2.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec4-slide-1/RACEMENT_3.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec4-slide-1/m/RACEMENT_3.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec4-slide-1/RACEMENT_4.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec4-slide-1/m/RACEMENT_4.jpg" v-else>
										</li>
									</ul>
								</div>
								<div class="btn prev slide-btn-sec4-slide-1"><img src="@/assets/images/experience/slide_btn_prev.png"></div>
								<div class="btn next slide-btn-sec4-slide-1"><img src="@/assets/images/experience/slide_btn_next.png"></div>
								<div class="sec4-slide-1-swiper-pagination swiper-pagination"></div>
							</div>
						</li>
					</ul>
				</div>
				<img src="@/assets/images/experience/sec4_1.png" class="content-size" v-if="!mainStore.isMobile">
				<img src="@/assets/images/experience/sec4_1_m.png" class="content-size" v-else>
      </section>
      <section class="section-5">
        <div class="txt-box sec5-1 content-size">
          <img src="@/assets/images/experience/sec5_1.png" v-if="!mainStore.isMobile">
          <img src="@/assets/images/experience/sec5_1_m.png" v-else>
        </div>
        <div class="slide-boxes content-size">
          <ul class="slide-list-wrap">
            <li class="slide-lists">
              <div class="txt-box" v-if="!mainStore.isMobile"><img src="@/assets/images/experience/sec5_slide_1_txt.png"></div>
              <div class="txt-box" v-else><img src="@/assets/images/experience/sec5_slide_1_txt_m.png"></div>
              <div class="slide">
                <div class="sec5-slide-1">
                  <ul class="slide-obj swiper-wrapper">
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_0.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_0.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_1.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_1.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_2.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_2.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_3.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_3.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_4.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_4.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_5.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_5.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_6.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_6.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_7.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_7.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_8.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_8.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_9.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_9.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_10.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_10.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_11.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_11.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_12.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_12.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-1/seamless_13.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-1/m/seamless_13.jpg" v-else>
										</li>
                  </ul>
                </div>
                <div class="btn prev slide-btn-sec5-slide-1"><img src="@/assets/images/experience/slide_btn_prev.png"></div>
                <div class="btn next slide-btn-sec5-slide-1"><img src="@/assets/images/experience/slide_btn_next.png"></div>
                <div class="sec5-slide-1-swiper-pagination swiper-pagination"></div>
              </div>
            </li>
            <li class="slide-lists">
              <div class="txt-box" v-if="!mainStore.isMobile"><img src="@/assets/images/experience/sec5_slide_2_txt.png"></div>
              <div class="txt-box" v-else><img src="@/assets/images/experience/sec5_slide_2_txt_m.png"></div>
              <div class="slide">
                <div class="sec5-slide-2">
                  <ul class="slide-obj swiper-wrapper">
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_0.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_0.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_1.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_1.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_2.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_2.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_3.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_3.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_4.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_4.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_5.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_5.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_6.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_6.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_7.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_7.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_8.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_8.jpg" v-else>
										</li>
                    <li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_9.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_9.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_10.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_10.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_11.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_11.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_12.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_12.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-2/special_13.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-2/m/special_13.jpg" v-else>
										</li>
                  </ul>
                </div>
                <div class="btn prev slide-btn-sec5-slide-2"><img src="@/assets/images/experience/slide_btn_prev.png"></div>
                <div class="btn next slide-btn-sec5-slide-2"><img src="@/assets/images/experience/slide_btn_next.png"></div>
                <div class="sec5-slide-2-swiper-pagination swiper-pagination"></div>
              </div>
            </li>
            <li class="slide-lists">
              <div class="txt-box" v-if="!mainStore.isMobile"><img src="@/assets/images/experience/sec5_slide_3_txt.png"></div>
              <div class="txt-box" v-else><img src="@/assets/images/experience/sec5_slide_3_txt_m.png"></div>
              <div class="slide">
                <div class="sec5-slide-3">
                  <ul class="slide-obj swiper-wrapper">
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_0.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_0.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_1.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_1.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_2.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_2.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_3.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_3.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_4.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_4.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_5.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_5.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_6.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_6.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_7.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_7.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_8.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_8.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_9.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_9.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_10.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_10.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_11.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_11.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_12.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_12.jpg" v-else>
										</li>
										<li class="swiper-slide slide-obj-list">
											<img src="@/assets/images/experience/sec5-slide-3/winner_13.jpg" v-if="!mainStore.isMobile">
											<img src="@/assets/images/experience/sec5-slide-3/m/winner_13.jpg" v-else>
										</li>
                  </ul>
                </div>
                <div class="btn prev slide-btn-sec5-slide-3"><img src="@/assets/images/experience/slide_btn_prev.png"></div>
                <div class="btn next slide-btn-sec5-slide-3"><img src="@/assets/images/experience/slide_btn_next.png"></div>
                <div class="sec5-slide-3-swiper-pagination swiper-pagination"></div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="section-6">
        <div class="img-box sec6-1 content-size">
					<ul>
						<li><img src="@/assets/images/experience/gifs/0.gif"></li>
						<li><video src="@/assets/images/experience/gifs/1.mp4" autoplay playsinline loop muted></video></li>
						<li><img src="@/assets/images/experience/gifs/2.gif"></li>
					</ul>
					<img src="@/assets/images/experience/sec6.jpg" v-if="!mainStore.isMobile" class="content-size">
					<img src="@/assets/images/experience/sec6_m.jpg" v-else>
				</div>
        <div class="img-box sec6-2">
					<img src="@/assets/images/experience/sec6_2.png" v-if="!mainStore.isMobile">
<!--					<img src="@/assets/images/experience/sec6_2.png" v-else>-->
				</div>
        <div class="btn-box">
					<router-link to="/digital" class="common-btn">
						LEARN MORE ON<br>
						DIGITAL
					</router-link>
        </div>
      </section>
		</div>
	</div>
</template>