<script setup>
import {useMainStore} from "@/stores/mainStore";
const mainStore = useMainStore();

</script>
<script>
export default {
  name: "comp-comms",
  components: {  },
  props: [],
  methods: {
    animateNumbers(container) {
      const numElements = container.querySelectorAll('.num')
      const progressBars = container.querySelectorAll('.progressbar > div')

      progressBars.forEach(bar => {
        const targetWidth = parseFloat(bar.innerText)
        const target = parseInt(targetWidth)
        let current = 0
        const steps = 50
        const duration = 1500
        const interval = duration / steps
        const increment = target / steps

        // 시작 시 0으로 설정
        bar.style.width = '0%'

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            bar.style.width = `${target}%`
            clearInterval(counter)
          } else {
            bar.style.width = `${Math.round(current)}%`
          }
        }, interval)
      })

      numElements.forEach(el => {
        const target = parseFloat(el.innerText)
        let current = 0
        const steps = 50
        const increment = target / steps
        const duration = 1500
        const interval = duration / steps

        const decimalPlaces = el.innerText.includes('.')
            ? el.innerText.split('.')[1].length
            : 0

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            el.innerText = target.toFixed(decimalPlaces)
            clearInterval(counter)
          } else {
            el.innerText = current.toFixed(decimalPlaces)
          }
        }, interval)
      })
    },

    initNumberAnimation() {
      const containers = document.querySelectorAll('.numbers-container')
      containers.forEach(container => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.animateNumbers(container) // 해당 컨테이너의 애니메이션 시작
              observer.disconnect() // 애니메이션 후 더 이상 관찰하지 않음
            }
          })
        }, { threshold: 0.1 })

        observer.observe(container) // 각 컨테이너를 관찰
      })
    },
  },
  mounted(){
    this.initNumberAnimation()
  },
  unmounted(){
  },
  updated() {
  },
  computed: {
  },
  watch:{
  }
}
</script>
<template>
  <div class="container comms">
    <div class="inner-container">
      <section class="section-1">
        <h1>COMMS</h1>
        <span class="des">MEDIA PARTNERSHIP</span>
        <img  src="@/assets/images/common/nike_logo_black.svg" alt="">
      </section>
      <section class="section-2">
        <img v-if="!mainStore.isMobile" src="@/assets/images/comms/title.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/comms/title_m.png" alt="">
      </section>
      <section class="section-3">
        <div class="key-results">
          <p>ANTHEM</p>
        </div>
        <div class="numbers-container">
          <div>
            <span class="sub">Total Reach</span>
            <div>
              <span class="num">81</span><span>K</span>
            </div>
          </div>
          <div>
            <span class="sub">COVERAGES</span>
            <div>
              <span class="num">12</span>
            </div>
          </div>
        </div>
        <video v-if="!mainStore.isMobile" class="anthem" src="@/assets/videos/6-Comms-Anthem.mp4" autoplay loop muted playsinline></video>
        <div v-if="mainStore.isMobile" class="video-01">
          <video src="@/assets/videos/6-Comms-Anthem_M.mp4" autoplay loop playsinline muted="muted"></video>
        </div>
<!--        <img v-if="!mainStore.isMobile" class="anthem" src="@/assets/images/comms/6-Comms-Anthem.gif" alt="">-->
      </section>
      <section class="section-4">
        <div class="key-results">
          <p>NIKE RUN JEJU</p>
        </div>
        <div class="numbers-container">
          <div>
            <span class="sub">Total Reach</span>
            <div>
              <span class="num">1.5</span><span>M</span>
            </div>
          </div>
          <div>
            <span class="sub">COVERAGES</span>
            <div>
              <span class="num">51</span>
            </div>
          </div>
        </div>
        <img v-if="!mainStore.isMobile" src="@/assets/images/comms/wesend.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/comms/wesend_m.png" alt="">
        <video v-if="!mainStore.isMobile" class="anthem" src="@/assets/videos/6-Comms-Jeju.mp4" autoplay loop muted playsinline></video>
        <div v-if="mainStore.isMobile" class="video-02" >
          <video  src="@/assets/videos/6-Comms-Jeju_M.mp4" autoplay loop muted playsinline></video>
        </div>
        <img v-if="!mainStore.isMobile" src="@/assets/images/comms/arena.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/comms/arena_m.png" alt="">
        <div class="btn-box">
					<router-link to="/peg" class="common-btn">
            LEARN MORE ON<br>
            PEG41 PQ
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/comms';
</style>
