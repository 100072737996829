<script setup>
import CompHeader from '@/components/header.vue'
import CompFooter from '@/components/footer.vue'

import {useMainStore} from "@/stores/mainStore";
const mainStore = useMainStore();
	mainStore.isInited = false;
	let init = () =>{
		window.loaded = true;
		mainStore.isInited = true;
		document.querySelector('.container').classList.add('onShow');
	}
  let isMobile = () =>{
    let WW = window.innerWidth;
    mainStore.isMobile = WW <= 768;
  }
  isMobile()
  window.addEventListener('resize',isMobile);
	window.addEventListener('load',init);
</script>

<script>
export default {
  name: "app",
  props: [],
  /*data(){
		return {
			message: 'Hello, Vue!'
		};
  },*/
  methods: {
  },
  mounted(){
  },
}
</script>
<template>
  <CompHeader/>
	<router-view />
  <CompFooter/>
</template>

<style lang="scss">
	@import 'assets/scss/page';
</style>