import { defineStore } from "pinia"
import { ref } from 'vue'  // ref를 import 해야 합니다

export const useMainStore = defineStore('parallax_store', () => {
  // ref를 사용하여 반응형 상태 생성
  const _isShowPopup = ref(false);
  const _isMobile = ref(false);

  return {
    isMobile: _isMobile,
    isShowPopup: _isShowPopup
  }
})