<script setup>
import {useMainStore} from "@/stores/mainStore";
import {ref, watch } from "vue";
const mainStore = useMainStore();
</script>
<script>
export default {
  name: "comp-media",
  components: {  },
  props: [],
  methods: {
    animateNumbers(container) {
      const numElements = container.querySelectorAll('.num')
      const progressBars = container.querySelectorAll('.progressbar > div')

      progressBars.forEach(bar => {
        const targetWidth = parseFloat(bar.innerText)
        const target = parseInt(targetWidth)
        let current = 0
        const steps = 50
        const duration = 1500
        const interval = duration / steps
        const increment = target / steps

        // 시작 시 0으로 설정
        bar.style.width = '0%'

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            bar.style.width = `${target}%`
            clearInterval(counter)
          } else {
            bar.style.width = `${Math.round(current)}%`
          }
        }, interval)
      })

      numElements.forEach(el => {
        // 쉼표를 제거하고 숫자로 변환
        const target = parseFloat(el.innerText.replace(/,/g, ''))
        let current = 0
        const steps = 50
        const increment = target / steps
        const duration = 1500
        const interval = duration / steps

        const decimalPlaces = el.innerText.includes('.')
            ? el.innerText.split('.')[1].length
            : 0

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            // 최종 값에 쉼표 추가
            el.innerText = target.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            clearInterval(counter)
          } else {
            // 중간 값에도 쉼표 추가
            el.innerText = current.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
        }, interval)
      })
    },

    initNumberAnimation() {
      const containers = document.querySelectorAll('.numbers-container')
      containers.forEach(container => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.animateNumbers(container) // 해당 컨테이너의 애니메이션 시작
              observer.disconnect() // 애니메이션 후 더 이상 관찰하지 않음
            }
          })
        }, { threshold: 0.5 })

        observer.observe(container) // 각 컨테이너를 관찰
      })
    }
  },
  mounted(){
    Promise.all(
        Array.from(document.images)
            .filter(img => !img.complete)
            .map(img => new Promise(resolve => {
              img.onload = img.onerror = resolve;
            }))
    ).then(() => {
      this.initNumberAnimation();
    });
  },
  unmounted(){
  },
  updated() {
  },
  computed: {
  },
  watch:{
  }
}
</script>
<template>
  <div class="container media">
    <div class="inner-container">
      <section class="section-1">
        <h1>MEDIA</h1>
        <span class="des">AV | OOH | BRAND MEDIA | PERFORMANCE MEDIA</span>
        <img  src="@/assets/images/common/nike_logo_black.svg" alt="">
      </section>
      <section class="section-2">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/title.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/title_m.png" alt="">
      </section>
      <section class="section-3">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/awareness.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/awareness_m.png" alt="">
        <div class="numbers-container">
          <div>
            <div>
              <span class="num">87</span><span>%</span>
            </div>
            <span class="sub">19-34 TARGET REACH</span>
            <span class="des">103% vs FCST 84%</span>
          </div>
          <div>
            <div>
              <span class="num">24</span><span>M</span>
            </div>
            <span class="sub">VIEW</span>
            <span class="des">187% vs FCST 13M</span>
          </div>
          <div>
            <div>
              <span class="num">10</span><span>%</span>
            </div>
            <span class="sub">OOH OTS</span>
            <span class="des">*Opportunity To See</span>
          </div>
        </div>
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-4">
        <div class="vid-box">
<!--          <iframe src="https://player.vimeo.com/video/1024612027?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:relative;top:0;left:0;width:100%;height:100%;" title="FA24 Running_Media OOH Video Recap-9911"></iframe>-->
          <iframe src="https://www.youtube.com/embed/_4qCgS7m4y8?si=Od6F5b97ibN7wl7y" style="position:relative;top:0;left:0;width:100%;height:100%;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/video_txt.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/video_txt_m.png" alt="">
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-5">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/engagement.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/engagement_m.png" alt="">
        <div class="vid-box">
          <video v-if="!mainStore.isMobile" src="@/assets/videos/5_Media_Engagement.mp4" autoplay muted playsinline loop></video>
          <video v-if="mainStore.isMobile" src="@/assets/videos/5_Media_Engagement_M.mp4" autoplay muted playsinline loop></video>
        </div>
        <div class="numbers-container">
          <div>
            <div>
              <span class="num">1.6</span><span>M</span>
            </div>
            <span class="sub">TRAFFIC</span>
            <span class="des">NIKE.COM 176% VS FCST 568K SOB 92%</span>
          </div>
          <div>
            <div>
              <span class="num">25</span><span>K</span>
            </div>
            <span class="sub">CONVERSATION</span>
            <span class="des">NAVER BRAND CHAT AI</span>
          </div>
          <div>
            <div>
              <span class="num">5.1</span><span>K</span>
            </div>
            <span class="sub">FIRST TIME LOGIN</span>
            <span class="des">1.7K NEW MEMBER</span>
          </div>
        </div>
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/contributed.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/contributed_m.png" alt="">
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-6">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/conversion.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/conversion_m.png" alt="">
        <div class="numbers-container">
          <div>
            <div>
              <span class="num">15</span><span>K</span>
            </div>
            <span class="sub">ORDER</span>
            <span class="des">NIKE.COM</span>
          </div>
          <div>
            <div>
              <span>$</span><span class="num">846</span><span>K</span>
            </div>
            <span class="sub">DEMAND</span>
            <span class="des">NIKE.COM + APP</span>
          </div>
        </div>
        <div class="numbers-container number-shoes">
          <div>
            <span class="sub">Peg41 PQ</span>
            <span class="paid">Paid share</span>
            <div>
              <span class="num">89</span><span>%</span>
            </div>
            <span class="des">Paid share $102K</span>
            <img src="@/assets/images/media/num_img_01.png" alt="">
            <div class="progressbar">
              <div>89</div>
            </div>
          </div>
          <div>
            <span class="sub">Peg41</span>
            <span class="paid">Paid share</span>
            <div>
              <span class="num">92</span><span>%</span>
            </div>
            <span class="des">Paid share $260K</span>
            <img src="@/assets/images/media/num_img_02.png" alt="">
            <div class="progressbar">
              <div>92</div>
            </div>
          </div>
          <div>
            <span class="sub">Running FW</span>
            <span class="paid">Paid share</span>
            <div>
              <span class="num">91</span><span>%</span>
            </div>
            <span class="des">Paid share $712K</span>
            <img src="@/assets/images/media/num_img_03.png" alt="">
            <div class="progressbar">
              <div>91</div>
            </div>
          </div>
          <div>
            <span class="sub">Running AP</span>
            <span class="paid">Paid share</span>
            <div>
              <span class="num">80</span><span>%</span>
            </div>
            <span class="des">Paid share $105K</span>
            <img src="@/assets/images/media/num_img_04.png" alt="">
            <div class="progressbar">
              <div>80</div>
            </div>
          </div>
        </div>
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/paid.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/paid_m.png" alt="">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/top_traffic.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/top_traffic_m.png" alt="">
      </section>
      <section class="section-7">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/keytakeaway.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/keytakeaway_m.png" alt="">
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-8">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/viral.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/viral_m.png" alt="">
        <div class="numbers-container">
          <div>
            <div>
              <span>+</span><span class="num">50</span>
            </div>
            <span class="sub">SOCIAL POSTS</span>
          </div>
          <div>
            <div>
              <span>+</span><span class="num">26</span><span>K</span>
            </div>
            <span class="sub">Engagement</span>
          </div>
        </div>
        <img class="dot-line" src="@/assets/images/common/dot_line.png" alt="">
      </section>
      <section class="section-9">
        <img v-if="!mainStore.isMobile" src="@/assets/images/media/brand.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/media/brand_m.png" alt="">
        <div class="btn-box">
          <router-link to="/comms" class="common-btn">
            LEARN MORE ON<br>
            COMMS
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/media';
</style>