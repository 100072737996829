<script setup>
	let currentRouter = window.router;
  import {useMainStore} from "@/stores/mainStore";
  const mainStore = useMainStore();
</script>
<style lang="scss" scoped>
	@import '@/assets/scss/header';
</style>
<script>
export default {
  name: "comp-header",
  components: {  },
  data(){
    return {
      onMenu:false
    }
  },
  props: [],
  methods: {
    hasMenu(val){
      this.onMenu = val
    }
  },
  mounted(){
  },
  unmounted(){
  },
  updated() {
  },
  computed: {
  },
  watch:{
  }
}
</script>
<template>
  <header>
    <div class="inner-header">
			<router-link @click.prevent="hasMenu(false)" to="/"><img class="logo" src="@/assets/images/common/nike_logo_orange.svg"></router-link>
			<div :class="{'curr':onMenu === true}" class="menu-list">
        <router-link @click.prevent="hasMenu(false)" to="/">HOME</router-link>
        <router-link @click.prevent="hasMenu(false)" to="/experience">IRL EXPERIENCE</router-link>
        <router-link @click.prevent="hasMenu(false)" to="/digital">DIGITAL</router-link>
        <router-link @click.prevent="hasMenu(false)" to="/retail">RETAIL</router-link>
        <router-link @click.prevent="hasMenu(false)" to="/media">MEDIA</router-link>
        <router-link @click.prevent="hasMenu(false)" to="/comms">COMMS</router-link>
        <router-link @click.prevent="hasMenu(false)" to="/peg">PEG41 PQ</router-link>
      </div>
      <img @click="hasMenu(true)" v-if="mainStore.isMobile && onMenu === false" class="hamburger" src="@/assets/images/common/header_hamburger.svg" alt="">
      <img @click="hasMenu(false)" v-if="mainStore.isMobile && onMenu === true" class="close" src="@/assets/images/common/header_close.svg" alt="">
    </div>
  </header>
</template>