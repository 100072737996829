<script setup>
	let currentRouter = window.router;
</script>
<script>
export default {
  data(){
    return{
      currentPath:''
    }
  },
  methods: {
    scrollTop(){
      window.scrollTo(0,0)
    }
  },
  mounted() {
  },
  watch: {
    '$route'(to) {
      this.currentPath = to.path
    }
  },
}
</script>
<style lang="scss" scoped>
	@import '@/assets/scss/footer';
</style>
<template>
  <footer>
    <div class="inner-footer" :class="{'bk' : currentPath === '/peg'}">
			<img class="logo" src="@/assets/images/common/nike_logo_orange.svg">
			<img @click="scrollTop" class="top-btn" src="@/assets/images/common/top_btn.svg">
    </div>
  </footer>
</template>