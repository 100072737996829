<script setup>
  import {useMainStore} from "@/stores/mainStore";
  import {onMounted, ref, onUnmounted, watch } from "vue";
  const mainStore = useMainStore();

  onMounted(() => {
    document.querySelector('.container').classList.add('onShow');
  });
</script>
<template>
	<div class="container main">
<!--		{{ mainStore.isMobile }}-->
		<div class="inner-container">
      <section class="section-1">
        <div class="kv-box">
          <img src="@/assets/images/main/kv.jpg" v-if="!mainStore.isMobile">
          <img src="@/assets/images/main/kv_m.jpg" v-else>
        </div>
      </section>
      <section class="section-2 content-size">
        <div class="vid-box">
					<iframe width="560" height="315" src="https://www.youtube.com/embed/icCr4DsDQJ8?si=2qsfRLCHb8SxSAEN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </section>
      <section class="section-3 content-size">
        <div class="img-box">
          <img src="@/assets/images/main/sec3.png" v-if="!mainStore.isMobile">
          <img src="@/assets/images/main/sec3_m.png" v-else>
        </div>
				<div class="vid-box">
					<video src="@/assets/videos/home.mp4" v-if="!mainStore.isMobile" muted autoplay playsinline loop></video>
					<video src="@/assets/videos/home_m_1.mp4" v-if="mainStore.isMobile" muted autoplay playsinline loop></video>
					<video src="@/assets/videos/home_m_0.mp4" v-if="mainStore.isMobile" muted autoplay playsinline loop></video>
					<video src="@/assets/videos/home_m_2.mp4" v-if="mainStore.isMobile" muted autoplay playsinline loop></video>
				</div>
        <div class="btn-box">
					<router-link to="/experience" class="common-btn">
            LEARN MORE ON<br>
            IRL EXPERIENCE
					</router-link>
        </div>
      </section>
		</div>
	</div>
</template>