<script setup>
	import {useMainStore} from "@/stores/mainStore";
	import {onMounted, ref, onUnmounted, watch, nextTick } from "vue";
	const mainStore = useMainStore();

	import SwiperCore from 'swiper';
	import 'swiper/css';
	import 'swiper/css/navigation';
	import 'swiper/css/pagination';
	SwiperCore.use();

	let animateNumbers = (container) =>{
		const numElements = container.querySelectorAll('.num')
		const progressBars = container.querySelectorAll('.progressbar > div')

		progressBars.forEach(bar => {
			const targetWidth = parseFloat(bar.innerText)
			const target = parseInt(targetWidth)
			let current = 0
			const steps = 50
			const duration = 1500
			const interval = duration / steps
			const increment = target / steps

			// 시작 시 0으로 설정
			bar.style.width = '0%'

			const counter = setInterval(() => {
				current += increment
				if (current >= target) {
					bar.style.width = `${target}%`
					clearInterval(counter)
				} else {
					bar.style.width = `${Math.round(current)}%`
				}
			}, interval)
		})

		numElements.forEach(el => {
			// 쉼표를 제거하고 숫자로 변환
			const target = parseFloat(el.innerText.replace(/,/g, ''))
			let current = 0
			const steps = 50
			const increment = target / steps
			const duration = 1500
			const interval = duration / steps

			const decimalPlaces = el.innerText.includes('.')
				? el.innerText.split('.')[1].length
				: 0

			const counter = setInterval(() => {
				current += increment
				if (current >= target) {
					// 최종 값에 쉼표 추가
					el.innerText = target.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
					clearInterval(counter)
				} else {
					// 중간 값에도 쉼표 추가
					el.innerText = current.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				}
			}, interval)
		})
	};
  let initNumberAnimation = () => {
    const containers = document.querySelectorAll('.numbers-container');
    const animatedContainers = new Set();

    // throttle 함수
    const throttle = (func, limit) => {
      let inThrottle;
      return function() {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => inThrottle = false, limit);
        }
      }
    }

    // 요소가 뷰포트 안에 있는지 확인
    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      // 요소가 화면의 아래에서 20% 지점에 도달했을 때 트리거
      return rect.top <= windowHeight * 0.9;
    };

    // 스크롤 핸들러
    const handleScroll = throttle(() => {
      containers.forEach(container => {
        if (animatedContainers.has(container)) return;

        if (isElementInViewport(container)) {
          animateNumbers(container);
          animatedContainers.add(container);
        }
      });

      if (animatedContainers.size === containers.length) {
        window.removeEventListener('scroll', handleScroll);
      }
    }, 100); // 100ms 마다 실행

    // 초기 체크
    handleScroll();

    // 스크롤 이벤트 리스너
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 정리 (Vue나 React에서 사용 시)
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };

	onMounted(() => {
    // nextTick(() => {
    //   initNumberAnimation();
    // });
    Promise.all(
        Array.from(document.images)
            .filter(img => !img.complete)
            .map(img => new Promise(resolve => {
              img.onload = img.onerror = resolve;
            }))
    ).then(() => {
      initNumberAnimation();
    });
	});

	onUnmounted(()=>{
	})
</script>
<template>
	<div class="container digital">
		<div class="inner-container">
      <section class="section-1">
        <h1>DIGITAL</h1>
        <span class="des">NIKE APP | NIKE.COM | SOCIAL | TC</span>
        <img  src="@/assets/images/common/nike_logo_black.svg" alt="">
      </section>
			<section class="section-2 content-size">
				<div class="txt-box sec2-1">
					<img src="../../assets/images/digital/sec2_1.png" v-if="!mainStore.isMobile">
					<img src="../../assets/images/digital/sec2_1_m.png" v-else>
				</div>
				<ul>
					<li>
						<div class="txt-box">
							<img src="@/assets/images/digital/sec2_2_txt_0.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec2_2_txt_0_m.png" v-else>
						</div>
						<div class="vid-box">
							<img src="@/assets/images/digital/gifs/1.gif" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/gifs/m/1.gif" v-else>
						</div>
						<div class="table-box numbers-container">
							<ul class="num-box">
								<li><span class="num">18.9</span><span>%</span></li>
								<li><span class="num">99</span><span>%</span></li>
								<li><span class="num">80</span><span>%</span></li>
							</ul>
							<img src="@/assets/images/digital/sec2_2_table_0.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec2_2_table_0_m.png" v-else>
						</div>
					</li>
					<li>
						<div class="txt-box">
							<img src="@/assets/images/digital/sec2_2_txt_1.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec2_2_txt_1_m.png" v-else>
						</div>
						<div class="vid-box">
							<ul>
								<li><img src="@/assets/images/digital/sec2_1_video_des_0.png"><video src="@/assets/videos/3-2-DIGITAL-GROUND-GAME-2.mp4" playsinline muted autoplay loop></video></li>
								<li><img src="@/assets/images/digital/sec2_1_video_des_1.png"><video src="@/assets/videos/3-2-DIGITAL-GROUND-GAME-1.mp4" playsinline muted autoplay loop></video></li>
							</ul>
						</div>
						<div class="table-box numbers-container">
							<ul class="num-box">
								<li><span class="num">66</span><span>%</span></li>
								<li><span>+</span><span class="num">11</span><span>%</span></li>
								<li><span class="num">45</span><span>K</span></li>
							</ul>
							<img src="@/assets/images/digital/sec2_2_table_1.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec2_2_table_1_m.png" v-else>
						</div>
					</li>
					<li>
						<div class="txt-box">
							<img src="@/assets/images/digital/sec2_2_txt_2.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec2_2_txt_2_m.png" v-else>
						</div>
						<div class="vid-box">
							<img src="@/assets/images/digital/gifs/3.gif" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/gifs/m/3.gif" v-else>
						</div>
						<div class="table-box numbers-container">
							<ul class="num-box">
								<li><span class="num">257</span><span>K</span></li>
								<li><span class="num">7.87</span><span>%</span></li>
							</ul>
							<img src="@/assets/images/digital/sec2_2_table_2.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec2_2_table_2_m.png" v-else>
						</div>
					</li>
				</ul>
			</section>
			<section class="section-3">
				<div class="txt-box sec3-1 content-size">
					<img src="@/assets/images/digital/sec3_1.png" v-if="!mainStore.isMobile">
					<img src="@/assets/images/digital/sec3_1_m.png" v-else>
				</div>
				<ul>
					<li class="content-size">
						<div class="txt-box">
							<img src="@/assets/images/digital/sec3_2_txt_1.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec3_2_txt_1_m.png" v-else>
						</div>
						<div class="table-box numbers-container">
							<ul class="num-box">
								<li><span class="num">488</span><span>K</span></li>
								<li><span class="num">9.4</span><span>K</span></li>
								<li><span class="num">2.6</span><span>K</span></li>
								<li><span class="num">4.6</span><span>K</span></li>
								<li><span class="num">7.94</span><span>%</span></li>
								<li><span class="num">127</span></li>
							</ul>
							<img src="@/assets/images/digital/sec3_2_table_0.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec3_2_table_0_m.png" v-else>
						</div>
					</li>
					<li class="content-size">
						<div class="txt-box">
							<img src="@/assets/images/digital/sec3_2_txt_2.png" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec3_2_txt_2_m.png" v-else>
						</div>
						<div class="gif-box">
							<ul>
								<li><img src="@/assets/images/digital/sec4_idle.png"></li>
								<li><img src="@/assets/images/digital/gifs/4.gif"></li>
								<li><img src="@/assets/images/digital/gifs/5.gif"></li>
								<li><img src="@/assets/images/digital/gifs/6.gif"></li>
							</ul>
						</div>
						<div class="des" v-if="!mainStore.isMobile">
							<img src="@/assets/images/digital/sec3_2_table_1_des.png">
						</div>
					</li>
				</ul>
			</section>
			<section class="section-4">
				<img src="@/assets/images/digital/sec4.png" v-if="!mainStore.isMobile">
				<img src="@/assets/images/digital/sec4_m.png" v-else>
				<div class="btn-box">
					<router-link to="/retail" class="common-btn">
						LEARN MORE ON<br>
						RETAIL
					</router-link>
				</div>
			</section>
		</div>
	</div>
</template>