<script setup>
import {useMainStore} from "@/stores/mainStore";
const mainStore = useMainStore();

</script>
<script>
export default {
  name: "comp-comms",
  components: {  },
  props: [],
  methods: {
    animateNumbers(container) {
      const numElements = container.querySelectorAll('.num')
      const progressBars = container.querySelectorAll('.progressbar > div')

      progressBars.forEach(bar => {
        const targetWidth = parseFloat(bar.innerText)
        const target = parseInt(targetWidth)
        let current = 0
        const steps = 50
        const duration = 1500
        const interval = duration / steps
        const increment = target / steps

        // 시작 시 0으로 설정
        bar.style.width = '0%'

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            bar.style.width = `${target}%`
            clearInterval(counter)
          } else {
            bar.style.width = `${Math.round(current)}%`
          }
        }, interval)
      })

      numElements.forEach(el => {
        const target = parseFloat(el.innerText)
        let current = 0
        const steps = 50
        const increment = target / steps
        const duration = 1500
        const interval = duration / steps

        const decimalPlaces = el.innerText.includes('.')
            ? el.innerText.split('.')[1].length
            : 0

        const counter = setInterval(() => {
          current += increment
          if (current >= target) {
            el.innerText = target.toFixed(decimalPlaces)
            clearInterval(counter)
          } else {
            el.innerText = current.toFixed(decimalPlaces)
          }
        }, interval)
      })
    },

    initNumberAnimation() {
      const containers = document.querySelectorAll('.numbers-container')
      containers.forEach(container => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.animateNumbers(container) // 해당 컨테이너의 애니메이션 시작
              observer.disconnect() // 애니메이션 후 더 이상 관찰하지 않음
            }
          })
        }, { threshold: 0.1 })

        observer.observe(container) // 각 컨테이너를 관찰
      })
    },
  },
  mounted(){
    this.initNumberAnimation()

  },
  unmounted(){
  },
  updated() {
  },
  computed: {
  },
  watch:{
  }
}
</script>
<template>
  <div class="container peg">
    <div class="inner-container">
      <section class="section-1">
        <h1>PEG41 PQ</h1>
<!--        <span class="des">SELL-THRU RESULTS</span>-->
        <img  src="@/assets/images/common/nike_logo_black.svg" alt="">
      </section>
      <section class="section-2">
        <img v-if="!mainStore.isMobile" src="@/assets/images/peg/title.png" alt="">
        <img v-if="mainStore.isMobile" src="@/assets/images/peg/title_m.png" alt="">
        <div v-if="!mainStore.isMobile" class="numbers-wrap">
          <div class="key-results">
            <p>MEN’S ST</p>
            <p>WOMEN’S ST</p>
          </div>
          <div class="numbers-container">
            <div>
              <span class="sub">TOTAL</span>
              <div>
                <span class="num">93</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">TOTAL</span>
              <div>
                <span class="num">85</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NDDC</span>
              <div>
                <span class="num">100</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSO</span>
              <div>
                <span class="num">100</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSP & NMP</span>
              <div>
                <span class="num">88</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NDDC</span>
              <div>
                <span class="num">80</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSO</span>
              <div>
                <span class="num">100</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSP & NMP</span>
              <div>
                <span class="num">86</span><span>%</span>
              </div>
            </div>
          </div>
          <img v-if="!mainStore.isMobile" src="@/assets/images/peg/consumer.png" alt="">
        </div>
        <div v-if="mainStore.isMobile" class="numbers-wrap">
          <div class="key-results">
            <p>MEN’S ST</p>
          </div>
          <div class="numbers-container">
            <div>
              <span class="sub">TOTAL</span>
              <div>
                <span class="num">93</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NDDC</span>
              <div>
                <span class="num">100</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSO</span>
              <div>
                <span class="num">100</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSP & NMP</span>
              <div>
                <span class="num">88</span><span>%</span>
              </div>
            </div>
          </div>
          <div class="key-results">
            <p>WOMEN’S ST</p>
          </div>
          <div class="numbers-container">
            <div>
              <span class="sub">TOTAL</span>
              <div>
                <span class="num">85</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NDDC</span>
              <div>
                <span class="num">80</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSO</span>
              <div>
                <span class="num">100</span><span>%</span>
              </div>
            </div>
            <div>
              <span class="sub">NSP & NMP</span>
              <div>
                <span class="num">86</span><span>%</span>
              </div>
            </div>
          </div>
          <img v-if="!mainStore.isMobile" src="@/assets/images/peg/consumer.png" alt="">
          <img v-if="mainStore.isMobile" src="@/assets/images/peg/consumer_m.png" alt="">
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/peg';
</style>
