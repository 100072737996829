import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../views/MainView.vue'
import ExperienceView from '../views/ExperienceView.vue'
import DigitalView from '../views/DigitalView.vue'
import MediaView from '../views/MediaView.vue'
import RetailView from '../views/RetailView.vue'
import CommsView from '../views/CommsView.vue'
import PegView from '../views/PegView.vue'
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'MainView',
      component: MainView,
      beforeEnter:function(to,form,next){
        window.router = 'MainView';
        next();
      }
    },
    {
      path: '/experience',
      name: 'ExperienceView',
      component: ExperienceView,
      beforeEnter:function(to,form,next){
        window.router = 'ExperienceView';
        next();
      }
    },
    {
      path: '/digital',
      name: 'DigitalView',
      component: DigitalView,
      beforeEnter:function(to,form,next){
        window.router = 'DigitalView';
        next();
      }
    },
    {
      path: '/media',
      name: 'MediaView',
      component: MediaView,
      beforeEnter:function(to,form,next){
        window.router = 'MediaView';
        next();
      }
    },
    {
      path: '/retail',
      name: 'RetailView',
      component: RetailView,
      beforeEnter:function(to,form,next){
        window.router = 'RetailView';
        next();
      }
    },
    {
      path: '/comms',
      name: 'CommsView',
      component: CommsView,
      beforeEnter:function(to,form,next){
        window.router = 'RetailView';
        next();
      }
    },
    {
      path: '/peg',
      name: 'PegView',
      component: PegView,
      beforeEnter:function(to,form,next){
        window.router = 'PegView';
        next();
      }
    },
    { path: '/:catchAll(.*)', component: MainView },
  ]
})

router.beforeEach((to, from, next) => {
  setTimeout(()=>{
    window.scrollTo(0,0);
    setTimeout(()=>{
      document.querySelector('.container').classList.add('onShow')
      document.querySelector('body').style.height='';
      document.querySelector('html').style.overflow='';
      document.querySelector('html').style.height='';
    },0);
  },0);
  next();
  window.dispatchEvent(new CustomEvent("routerpath", {
    detail: { name: to.path }
  }));
});


export default router